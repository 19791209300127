import React from "react";
import { graphql, Link } from "gatsby";

import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";

import leftChevron from "../img/left_chevron.png";
import rightChevron from "../img/right_chevron.png";

const getAllSlugsAndTitles = allPages => {
  return allPages.edges.map(p => {
    return {slug: p.node.fields.slug, title: p.node.frontmatter.title};
  });
};

const getNextPage = (slug, allPages) => {
  const slugs = getAllSlugsAndTitles(allPages);
  const index = slugs.findIndex((slugTitle) => slugTitle.slug === slug);
  return index < (slugs.length - 1) ? slugs[index + 1] : slugs[0];
};

const getPreviousPage = (slug, allPages) => {
  const slugs = getAllSlugsAndTitles(allPages);
  const index = slugs.findIndex((slugTitle) => slugTitle.slug === slug);
  return index > 0 ? slugs[index - 1] : slugs[slugs.length - 1];
};

const heroStyle = (image) => {
  return {
    background: 'url(' + image + ') no-repeat 50% 50%',
    backgroundSize: 'cover',
    position: 'relative',
  }
};

export const CaseStudyPageTemplate = ({
        title,
        challenge,
        impact,
        services,
        stats,
        summary1,
        summary2,
        contentComponent,
        heroTop,
        imageMid1,
        imageMid2,
        imageMid3,
        heroMid,
        imageFooter1,
        imageFooter2,
        heroFooter,
        allPages,
        slug,
}) => {

  const nextPage = getNextPage(slug, allPages);
  const previousPage = getPreviousPage(slug, allPages);

  const PageContent = contentComponent || Content;

  return (
    <React.Fragment>
      <section className="hero top" style={heroStyle(heroTop)}>
      </section>
       <div className="case-study-navigation">
        <div className="container">
            <div className="level is-mobile">
             <div className="level-left">
              {
                 previousPage && (
                  <Link className="nav-link" to={previousPage.slug}>
                     <div className="level">
                       <div className="level-item">
                         <img className="navChevron" src={leftChevron} alt="left arrow"/>
                         <div className='nav-link-text left'>
                            <div className='h4'>PREVIOUS PROJECT</div>
                            <div className='h3'>{previousPage.title}</div>
                          </div>
                        </div>
                     </div>
                   </Link>
                 )
               }
             </div>
             <div className="level-right">
               {
                 nextPage && (

                   <Link className="nav-link" to={nextPage.slug}>
                     <div className="level">
                       <div className="level-item">

                         <div className='nav-link-text right'>
                            <div className='h4'>NEXT PROJECT</div>
                            <div className='h3'>{nextPage.title}</div>
                          </div>


                           <img className="navChevron" src={rightChevron} alt="right arrow"/>
                        </div>
                     </div>
                   </Link>
                 )
               }
             </div>
          </div>
        </div>
      </div>
      <div className="container top-section">
        <div className="columns">

          <div className="column offset-1">
            <h1 className="h2">{title}</h1>
          </div>
        </div>
        <div className="columns">

          <div className="column offset-1 is-7">

            <h3>The Challenge</h3>
            <PageContent className="content body-1" content={challenge} />
            <h3>The Impact</h3>
            <PageContent className="content body-1" content={impact} />
          </div>

          <div className="column is-4 study-services is-offset-1">
            <h3>Services</h3>
            <ul className="h4">
               {services.map((s, i) => (<li>{s.name}</li>))}
            </ul>
          </div>
        </div>
      </div>
      <div className="case-study-stats">
        <div className="container">
          <div className="level">
            {stats.map((stat, i) => (
              <div className="level-item">
                <div>
                  <div className="h2">{stat.number}</div>
                  <div className="stat-description ">{stat.description}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="container">
        <div className="columns">
          <div className="column">
            <img src={imageMid1} className="image-mid-1" alt="{title} in action"/>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <img src={imageMid2} alt="{title} in action" />
          </div>
          <div className="column">
            <img src={imageMid3} alt="{title} in action"/>
          </div>
        </div>
      </div>
      <div className="container summary-1">
        <div className="columns is-centered">
          <div className="column is-8">
            <h3>Summary</h3>
             <PageContent className="content body-1" content={summary1} />
          </div>
        </div>
      </div>
      <section className="hero" style={heroStyle(heroMid)}>
      </section>
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-8 summary-2">
            <PageContent className="content body-1" content={summary2} />
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <img src={imageFooter1} alt="{title} in action"/>
          </div>
          <div className="column">
            <img src={imageFooter2} alt="{title} in action" />
          </div>
        </div>

      </div>
      <section className="hero" style={heroStyle(heroFooter)}>
      </section>


    </React.Fragment>
  );
};

const CaseStudyPage = ({ data }) => {
  const { markdownRemark: post, allMarkdownRemark: allPages } = data;
  const title = post.frontmatter.title;

  return (
    <Layout pageId="case-study" metadata={{ title, description: post.frontmatter.challenge }}>
      <CaseStudyPageTemplate
        contentComponent={HTMLContent}
        title={title}
        challenge={post.frontmatter.challenge}
        impact={post.frontmatter.impact}
        services={post.frontmatter.services}
        stats={post.frontmatter.stats}
        summary1={post.frontmatter.summary1}
        summary2={post.html}
        heroTop={post.frontmatter.heroTop.childImageSharp.original.src}
        imageMid1={post.frontmatter.imageMid1.childImageSharp.original.src}
        imageMid2={post.frontmatter.imageMid2.childImageSharp.original.src}
        imageMid3={post.frontmatter.imageMid3.childImageSharp.original.src}
        heroMid={post.frontmatter.heroMid.childImageSharp.original.src}
        imageFooter1={post.frontmatter.imageFooter1.childImageSharp.original.src}
        imageFooter2={post.frontmatter.imageFooter2.childImageSharp.original.src}
        heroFooter={post.frontmatter.heroFooter.childImageSharp.original.src}
        allPages={allPages}
        slug={post.fields.slug}
      />
    </Layout>
  );
};

export default CaseStudyPage;

export const caseStudyPageQuery = graphql`
  query CaseStudyPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      fields {
        slug
      }
      frontmatter {
        title
        challenge
        impact
        services {
          name
        }
        stats {
          number
          description
        }
        heroTop {
          childImageSharp {
            original {
              src
            }
          }
        }
        imageMid1 {
          childImageSharp {
            original {
              src
            }
          }
        }
        imageMid2 {
          childImageSharp {
            original {
              src
            }
          }
        }
        imageMid3 {
          childImageSharp {
            original {
              src
            }
          }
        }
        summary1
        heroMid {
          childImageSharp {
            original {
              src
            }
          }
        }
        imageFooter1 {
          childImageSharp {
            original {
              src
            }
          }
        }
        imageFooter2 {
          childImageSharp {
            original {
              src
            }
          }
        }
        heroFooter {
          childImageSharp {
            original {
              src
            }
          }
        }
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "case-study-page" } } },
      sort: { order: ASC, fields: [frontmatter___order]},
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`;
